<template>
  <div class="aboutOurCompany">
    <div class="title"><h2>تطبيق قراءتي</h2></div>
    <v-divider></v-divider>
    <v-container>
      <h3 class="blue--text">
        «تطبيق قراءتي».. خدمة «قابضة المياه» لتسجيل قراءة العداد في 4 خطوات
      </h3>

      <v-row class="mt-10">
        <v-col class="subClass" cols="12" sm="12" xs="12" lg="9">
          <h4>
            <p>
              خدمات عدة، تقدمها الشركة القابضة لمياه الشرب والصرف الصحى،
              للمواطنين، وذلك لتوفير كل سبل التواصل بين الشركة بجميع فروعها داخل
              المحافظات والمواطنين.
            </p>
          </h4>

          <v-img
            lazy-src="@/assets/Images/loading.gif"
            src="@/assets/Images/readme.jpg"
          ></v-img>
          <h4>
            <p>
              ومن ضمن تلك الخدمات، أتاحت الشركة القابضة لمياه الشرب والصرف
              الصحى، تطبيق «قراءتى»، والذى يتيح للمواطن تسجيل قراءة العداد
              بنفسه، وإضافة صورة العداد وهو ما يضمن دقة القراءة، على أن ترسل
              القراءات من يوم 1 إلى 15 من كل شهر وذلك لكل الشركات التابعة للشركة
              القابضة.
            </p>
          </h4>
          <h4>
            <p>
              وذلك وفقا لما نشرته الشركة القابضة لمياه الشرب والصرف الصحى على
              الصفحة الرسمية لها.
            </p>
          </h4>
          <h4>
            <p>
              ويسمح تطبيق «قراءتي» لتسجيل قراءة عدادات المياه الخاصة للمواطنين
              عبر الهاتف، كأحد الإجراءات الاحترازية التي انتهجتها الشركة لمواجهة
              فيروس كورونا المستجد.
            </p>
          </h4>
          <h4>
            <p>
              أى يتيح هذا التطبيق للمواطن تسجيل قراءة العداد بنفسه، وإبلاغ
              القراءات، وذلك لكل الشركات التابعة بمختلف محافظات الجمهورية.
            </p>
          </h4>
          <h4>
            <p>ويمكن للمواطن تحميل التطبيق من خلال Google play مجانًا.</p>
          </h4>
          <v-row justify="center">
            <a @click="openApp(1)" class="linkApp"
              ><v-img
                lazy-src="@/assets/Images/loading.gif"
                src="@/assets/Images/37.png"
                class="mobGoogleClass"
                width="230px"
                height="80px"
            /></a>
            <a @click="openApp(2)" class="linkApp"
              ><v-img
                lazy-src="@/assets/Images/loading.gif"
                src="@/assets/Images/38.png"
                class="mobAppClass"
                width="230px"
                height="80px"
            /></a>
          </v-row>
          <h3 class="brown--text">
            <p>
              وخلال السطور التالية، تستعرض «الوطن» كيفية استخدام تطبيق
              «قراءتي»:.
            </p>
          </h3>
          <h4>
            <p>
              - يحتاج الدخول لأول مرة تسجيل العميل لبياناته الموجودة على فاتورة
              المياه مثل اسم المشترك ورقم الاشتراك واختيار الشركة التابع لها
              ورقم الهاتف وتعيين كلمة مرور ثم إعادة إدخالها مرة أخرى.
            </p>
          </h4>
          <h4>
            <p>
              يمكن تسجيل أكثر من عداد للعميل ويتيح البرنامج أيضًا للعميل ميزة
              الاستعلام عن آخر قراءة قام العميل بإدخالها.
            </p>
          </h4>
          <h4>
            <p>
              يستطيع العميل تسجيل العميل للقراءة الخاصة به في الخانة المخصصة
              للقراءة الحالية على التطبيق..
            </p>
          </h4>
          <h4>
            <p>
              كما يتيح للعميل إضافة صورة العداد وهو ما يضمن لك أن تكون القراءة
              دقيقة بنسبة 100%.
            </p>
          </h4>
          <h4>
            <p>
              ليس ذلك فقط بل تقوم الشركة القابضة لمياه الشرب والصرف الصحى، برفع
              حالة الطوارئ، داخل جميع شركات المياه بالمحافظات، عند حدوث موجة من
              التقلبات الجوية، وسقوط كميات كبيرة من مياه الأمطار، من خلال اتخاذ
              كل التدابير لسحب تجمعات مياه الأمطار، تجنبًا لحدوث أي أضرار أو
              تعطل في الحركة المرورية داخل المناطق والطرق على مستوى الجمهورية..
            </p>
          </h4>
        </v-col>
        <v-col cols="12" sm="12" xs="12" lg="3">
          <Link></Link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Link from "../../components/link.vue";
export default {
  name: "Anelsherka",
  components: {
    Link,
  },
  data() {
    return {
      ArrayOFAnElsherka: [
        {
          icon: "mdi-arrow-right-drop-circle-outline",
          title: "رساله الشركة",
          img: require("@/assets/Images/7.png"),
        },
        {
          icon: "",
          title: "صفحتنا علي الفيسبوك",
          img: require("@/assets/Images/8.jpg"),
          subText: "صفحتنا",
        },
        {
          icon: "",
          title: "مواقع هامة",
          listofItem: [
            {
              subImportantSite: "بوابه الحكومه المصريه",
              link: "https://www.egypt.gov.eg/arabic/home.aspx",
            },
            {
              subImportantSite: "رئاسه مجلس الوزراء",
              link: "https://www.cabinet.gov.eg/Arabic/Pages/default.aspx",
            },

            {
              subImportantSite: "الشركة القابضة لمياه الشرب و الصرف الصحى",
              link: "https://www.hcww.com.eg/ar/default.aspx",
            },
          ],
        },
      ],
    };
  },

  methods: {
    openApp(num) {
      if (num == 1) {
        window.open(
          "https://apps.apple.com/eg/app/%D9%82%D8%B1%D8%A7%D8%A1%D8%AA%D9%8A-%D9%82%D8%B1%D8%A7%D8%A1%D8%A9-%D8%B9%D8%AF%D8%A7%D8%AF-%D8%A7%D9%84%D9%85%D9%8A%D8%A7%D9%87/id1518978525",
          "_blank"
        );
      } else if (num == 2) {
        window.open(
          "https://play.google.com/store/apps/details?id=com.hcww.it.myreading",
          "_blank"
        );
      }
    },
  },
};
</script>
<style >
.mobAppClass,
.mobGoogleClass {
  border-radius: 10px;
}
.linkApp {
  padding: 15px;
}
</style>
